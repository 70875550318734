import flatpickr from 'flatpickr'
import { Controller } from '@hotwired/stimulus'

export default class DateTimeController extends Controller {
  static values = {
    enableTime: {
      default: true,
      type: Boolean,
    },
    min: String,
    max: String,
  }

  connect() {
    flatpickr(this.element, {
      enableTime: this.enableTimeValue,
      dateFormat: this.enableTimeValue ? 'Y-m-d H:i' : 'Y-m-d',
      time_24hr: true,
      defaultDate: this.element.value,
      minDate: this.hasMinValue && this.minValue,
      maxDate: this.hasMaxValue && this.maxValue,
      onChange: (selectedDates, dateStr, _instance) => {
        this.element.value = dateStr
      },
    })
  }
}
